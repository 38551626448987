.status-alerts-wrapper {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 0px;
  left: 0;
  top: 0;
}

.status-alerts-wrapper * {
  box-sizing: border-box;
}

.status-alert {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  max-height: 600px;
  max-width: 780px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 14px;
  padding-left: 14px;
  -webkit-transition: opacity 800ms ease, max-height 800ms ease-out, -webkit-transform 500ms ease-out;
  transition: opacity 800ms ease, max-height 800ms ease-out, -webkit-transform 500ms ease-out;
  transition: transform 500ms ease-out, opacity 800ms ease, max-height 800ms ease-out;
  transition: transform 500ms ease-out, opacity 800ms ease, max-height 800ms ease-out, -webkit-transform 500ms ease-out;
}

.status-alert.is-transparent {
  max-height: 0px;
  opacity: 0;
  -webkit-transform: translate(0px, -100%);
  -ms-transform: translate(0px, -100%);
  transform: translate(0px, -100%);
}

.status-alert.is-transparent.is-hidden {
  display: none;
}

.status-alert__box {
  position: relative;
  display: block;
  min-height: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 14px 60px 17px 46px;
  border-radius: 5px;
  background-color: #464c55;
  box-shadow: 0 15px 65px -9px rgba(46, 51, 58, .85);
  color: #fff;
}

.status-alert__box.is-green-success {
  background-color: #107c2e;
}

.status-alert__box.is-red-error {
  background-color: #ff3b20;
}

.status-alert__box.is-orange-warning {
  background-color: #f90;
}

.status-alert__icon-holder {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 40px;
  height: 19px;
  padding-top: 10px;
  padding-left: 11px;
}

.status-alert__text {
  font-size: 14px;
}

.status-alert__icon {
  width: 24px;
  height: 24px;
  text-align: center;
}

.status-alert__icon.is-info-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ni4wMyA0NS45MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5pbmZvLWljb24td2hpdGU8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNi43NCw2Ljc0QTIyLjE3LDIyLjE3LDAsMCwxLDIzLDAsMjIuMTksMjIuMTksMCwwLDEsMzkuMjksNi43NCwyMi4xMiwyMi4xMiwwLDAsMSw0NiwyM2EyMi4xMiwyMi4xMiwwLDAsMS02Ljc0LDE2LjIyQTIyLjE5LDIyLjE5LDAsMCwxLDIzLDQ1LjkyLDIyLjE3LDIyLjE3LDAsMCwxLDYuNzQsMzkuMTgsMjIuMTIsMjIuMTIsMCwwLDEsMCwyMywyMi4xMiwyMi4xMiwwLDAsMSw2Ljc0LDYuNzRaTTEwLDM2YTE3LjcsMTcuNywwLDAsMCwxMyw1LjM5QTE3LjY3LDE3LjY3LDAsMCwwLDM2LDM2YTE3Ljc3LDE3Ljc3LDAsMCwwLDUuMzktMTNBMTcuNzcsMTcuNzcsMCwwLDAsMzYsOS45MiwxNy42NywxNy42NywwLDAsMCwyMyw0LjUzLDE3LjcsMTcuNywwLDAsMCwxMCw5LjkyLDE3Ljc3LDE3Ljc3LDAsMCwwLDQuNjMsMjMsMTcuNzcsMTcuNzcsMCwwLDAsMTAsMzZaTTIwLjcsMTYuMDZWMTEuNDNoNC42M3Y0LjYzWm0wLDE4LjQzVjIwLjdoNC42M1YzNC40OVoiLz48L2c+PC9nPjwvc3ZnPg==");
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.status-alert__icon.is-close-icon {
  border-radius: 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMy4wOSAzMi45OCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5jbG9zZS1pY29uLXdoaXRlPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTMzLjA5LDMuMzIsMTkuODEsMTYuNDksMzMuMDksMjkuNjYsMjkuNzcsMzMsMTYuNDksMTkuODEsMy4zMiwzMywwLDI5LjY2LDEzLjE3LDE2LjQ5LDAsMy4zMiwzLjMyLDAsMTYuNDksMTMuMTcsMjkuNzcsMFoiLz48L2c+PC9nPjwvc3ZnPg==");
  background-position: 50% 50%;
  background-size: 15px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: auto;
  width: 34px;
  height: 34px;
}

.status-alert__icon.is-close-icon:hover {
  background-color: hsla(0, 0%, 100%, .15);
}

.status-alert__icon.is-close-icon:active {
  background-color: rgba(0, 0, 0, .35);
}

.status-alert__icon.is-check {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OC41IDM2Ljk5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmNoZWNrLXdoaXRlPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE1LjM5LDI5LjM2LDQ0Ljc1LDAsNDguNSwzLjg4LDE1LjM5LDM3LDAsMjEuNmwzLjg4LTMuODhaIi8+PC9nPjwvZz48L3N2Zz4=");
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.status-alert__icon.is-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OS44IDQzIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmVycm9yLWljb24td2hpdGU8L3RpdGxlPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXllcl8xLTIiIGRhdGEtbmFtZT0iTGF5ZXIgMSI+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMCw0MywyNSwwLDQ5LjgsNDNaTTI3LjE4LDI3LjE4VjE4LjA1SDIyLjYxdjkuMTNabTAsOVYzMS42NEgyMi42MVYzNi4yWiIvPjwvZz48L2c+PC9zdmc+");
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.status-alert__icon-on-right-holder {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 40px;
  height: 44px;
  padding-top: 6px;
  padding-right: 5px;
  cursor: pointer;
}

.status-alert__padding-wrapper {
  padding-top: 16px;
}

.font-icon.status-alert__icon-span {
  font-size: 20px;
}

.font-icon.status-alert__icon-span-copy {
  font-size: 20px;
}

.font-icon.status-alert__close-icon {
  top: -4px;
  width: 24px;
  height: 24px;
  padding-top: 4px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.font-icon.status-alert__close-icon:hover {
  background-color: hsla(0, 0%, 100%, .15);
}

.font-icon.status-alert__close-icon:active {
  background-color: rgba(0, 0, 0, .27);
}
